import styled, { css } from "styled-components";
import { colors } from "../../variables";


export const Content = styled.div`
  text-align: center;
  width: 50%;
  padding: 0px 10px;
`;
export const TitleVideo = styled.div`
  color: #000;
  padding: 0px;
  margin: 0px;
  font-family: 'Pitch Sans Test';
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
`;
export const OnMobile = styled.div`
  
  display: none;
  @media (max-width: 767px){
    display: block;
    button{
      width: 100%;
    }
  }
  
`;

export const OnDesktop = styled.div`
  display: block;
  @media (max-width: 767px){
    display: none;
  }
`;

export const SequencesWrapper = styled.div`
  width: 1000px;
  background-color: #fff;
  border-radius: 0px;
  position: relative;
  margin-top: 150px;
  z-index: 30;
  left: 50%;
  margin-left: -500px;


  
  @media (max-width: 991px){
    width: 95%;
    left: 50%;
    margin-left: -47.5%;
    margin-top: 50px;
  }
  @media (max-width: 767px){
    margin-top: 50px;
  }
  @media (max-width: 468px){
    position: relative;
    margin-top: 20px;
    
  }
`;

export const SequencesContent = styled.div`
  padding: 20px 30px;
  color: #000;
  text-align: center;
  
  @media (max-width: 468px){
    padding: 20px 10px;
  }
`;

export const SequencesTitle = styled.div`
  margin-top: 0px;
  font-size: 22px;
  padding-top: 0px;
  font-weight: bold;
  color: #000;
  font-family: 'Pitch Sans Test';
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 991px){
    font-size: 20px;
  }
`;

export const SequencesText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  text-transform: normal;
  color: #000;
`;

export const StepNumber = styled.div`
  font-size: 12px;
  text-align: center;
  color: #000;
  font-family: 'Pitch Sans Test';
  text-transform: uppercase;
  z-index: 40;
  margin-top: 30px;
  font-weight: bold;
  position: relative;
  @media (max-width: 991px){
    display: block;
    text-align: center;
    color: #000;
    margin-top: 5px;
  }
`;

export const SequencesList = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  flex-wrap: wrap;
  
`;

export const SequencesListElement = styled.div`
text-align: center;
flex: 1 0 21%; /* explanation below */
  margin: 5px;
  @media (max-width: 991px){
    display: flex;
    flex: inherit;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
}
  video, label, input{
    display: block;
    margin-bottom: 5px;
    @media (max-width: 991px){
      text-align: center;
  }
  }
  label{
    color: #000;
    padding: 0px;
    margin: 0px;
    font-family: 'Pitch Sans Test';
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
  }
  input{
    margin: 0 auto;
    margin-top: 10px;
  }
`;

export const Video = styled.video`
    width: 100%;
    height: auto;
    @media (max-width: 991px){
      width: 50%;
  }
`;
