import styled, { css } from "styled-components";
import { colors } from './../../variables';

export const ConfirmWrapper = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 0px;
  position: relative;
  margin-top: 120px;
  z-index: 30;
  left: 50%;
  margin-left: -200px;
  @media (max-width: 991px){
    position: relative;
    background-color: transparent;
    border: 0px;
    margin-top: 20px;
  }
  @media (max-width: 468px){
    position: relative;
    margin: 0 auto;
    width: 95%;
    margin-top: 20px;
    left: 50%;
    margin-left: -47.5%;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ccc;
`;

export const ConfirmContent = styled.div`
  padding: 0px;
  color: #000;
  text-align: center;
  @media (max-width: 991px){
    color: #000;
  }
  button{
    display: inline-block;
    @media (max-width: 991px){
      padding: 0px 50px;
    }
  }
`;

export const ConfirmTitle = styled.div`
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
  color: #000;
  font-family: 'Pitch Sans Test';
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 991px){
    color: #000;
  }
`;

export const ConfirmMessage = styled.div`
  margin-top: 20px;
  font-size: 12px;
  text-transform: normal;
  color: #000;
  @media (max-width: 991px){
    color: #000;
  }
`;

export const ResumeVideo = styled.div`
  background-color: #000;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  height: auto!important;
  
  margin: 0 auto;
  margin-top: 0px;
  border: 1px solid #aaa;
  @media (max-width: 767px){
    width: 100%;
    height: 200px;
  }
`;
export const ShareContainer = styled.div`
  background-color: #fff;
  padding: 15px 30px;
  text-align: center;
`;
export const ShareTitle = styled.div`
  font-size: 10px;
  color: #000;
  text-transform: uppercase;
  font-family: 'Pitch Sans Test';
  font-weight: bold;
  @media (max-width: 991px){
    color: #000;
  }
`;

export const ShareList = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
`;

export const ShareListItem = styled.li<{color: string}>`
  list-style: none;
  display: inline-block;
  margin: 5px 5px;
  color: #fff;
  
  a{
    
    color: #fff;
    display: flex;
    font-size: 11px;
    padding: 5px 5px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
  }
  img{
    height: 25px;
    width: auto;
    margin-right: 5px;
  }
`;