import * as React from 'react';
import Layout from '../layout';
import {Content, TitleVideo, OnDesktop, OnMobile, Video, StepNumber, SequencesWrapper, SequencesContent, SequencesTitle, SequencesText, SequencesList, SequencesListElement} from './styled';
import { colors } from '../../variables';
import video1 from './images/affirmation01_d.mp4';
import video2 from './images/affirmation02_d.mp4';
import video3 from './images/affirmation03_d.mp4';
import video4 from './images/affirmation04_d.mp4';
import video5 from './images/affirmation05_d.mp4';
import video6 from './images/affirmation06_d.mp4';
import video7 from './images/affirmation07_d.mp4';
import video8 from './images/affirmation08_d.mp4';

import videom1 from './images/affirmation01_m.mp4';
import videom2 from './images/affirmation02_m.mp4';
import videom3 from './images/affirmation03_m.mp4';
import videom4 from './images/affirmation04_m.mp4';
import videom5 from './images/affirmation05_m.mp4';
import videom6 from './images/affirmation06_m.mp4';
import videom7 from './images/affirmation07_m.mp4';
import videom8 from './images/affirmation08_m.mp4';

import poster1 from './images/poster01_d.jpg';
import poster2 from './images/poster02_d.jpg';
import poster3 from './images/poster03_d.jpg';
import poster4 from './images/poster04_d.jpg';
import poster5 from './images/poster05_d.jpg';
import poster6 from './images/poster06_d.jpg';
import poster7 from './images/poster07_d.jpg';
import poster8 from './images/poster08_d.jpg';

import posterm1 from './images/poster01_m.jpg';
import posterm2 from './images/poster02_m.jpg';
import posterm3 from './images/poster03_m.jpg';
import posterm4 from './images/poster04_m.jpg';
import posterm5 from './images/poster05_m.jpg';
import posterm6 from './images/poster06_m.jpg';
import posterm7 from './images/poster07_m.jpg';
import posterm8 from './images/poster08_m.jpg';
import { Button, ButtonAddMessage, AlignCenter, AlignRight, MobileMask } from '../../widgets/globaleStyledWidget';


import { Link, useHistory } from 'react-router-dom';


import livstickApp, { language } from '../../models/livstick';

import {FormattedMessage} from 'react-intl';
import manifest from '../../manifest.json';
import { COUNTRIES, COUNTRIES_DETAILS } from '../../lang/countries';
import { Observer } from 'mobx-react';
import { events } from '../../events';


const SequencesPage:React.FunctionComponent = () => {
  const history = useHistory();

  React.useEffect(() => {
    livstickApp.postEvent(events.landing_sequence)
  });

  return <Layout>
    <Observer>{() => 
    <SequencesWrapper>
      
      <SequencesContent>
        <StepNumber><FormattedMessage id="global_txt_step"/> 1/3</StepNumber>
        <SequencesTitle><FormattedMessage id="form_txt_affirmation1"/></SequencesTitle>
        <SequencesText><FormattedMessage id="form_txt_affirmation2"/></SequencesText>
        
          <OnDesktop>
            <SequencesList>
              <SequencesListElement>
                <Video controls poster={poster1}>
                    <source src={video1} type="video/mp4" />
                </Video>
                <label htmlFor="d1">SHINE AT FULL WATTAGE</label>
                <input id="d1" name="seqd" value="m01" type="radio" aria-label="SHINE AT FULL WATTAGE" onChange={() => livstickApp.clipCustom = "ulta/affirmation01"}/>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={poster2}>
                    <source src={video2} type="video/mp4" />
                </Video>
                <label htmlFor="d2">WELCOME CHANGE</label>
                <input id="d2" name="seqd" value="m02" type="radio" aria-label="WELCOME CHANGE" onChange={() => livstickApp.clipCustom = "ulta/affirmation02"}/>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={poster3}>
                    <source src={video3} type="video/mp4" />
                </Video>
                <label htmlFor="d3">STRONG AND UNSTOPPABLE</label>
                <input id="d3" name="seqd" value="m03" type="radio" aria-label="STRONG AND UNSTOPPABLE" onChange={() => livstickApp.clipCustom = "ulta/affirmation03"}/>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={poster4}>
                    <source src={video4} type="video/mp4" />
                </Video>
                <label htmlFor="d4">DEVOTED TO THIS MOMENT</label>
                <input id="d4" name="seqd" value="m04" type="radio" aria-label="DEVOTED TO THIS MOMENT" onChange={() => livstickApp.clipCustom = "ulta/affirmation04"}/>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={poster5}>
                    <source src={video5} type="video/mp4" />
                </Video>
                <label htmlFor="d5">Layered complex and divine</label>
                <input id="d5" name="seqd" value="m05" type="radio" aria-label="Layered complex and divine" onChange={() => livstickApp.clipCustom = "ulta/affirmation05"}/>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={poster6}>
                    <source src={video6} type="video/mp4" />
                </Video>
                <label htmlFor="d6">FREE AS THE AIR</label>
                <input id="d6" name="seqd" value="m06" type="radio" aria-label="FREE AS THE AIR" onChange={() => livstickApp.clipCustom = "ulta/affirmation06"}/>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={poster7}>
                    <source src={video7} type="video/mp4" />
                </Video>
                <label htmlFor="d7">SURROUND YOURSELF WITH GOOD</label>
                <input id="d7" name="seqd" value="m07" type="radio" aria-label="SURROUND YOURSELF WITH GOOD" onChange={() => livstickApp.clipCustom = "ulta/affirmation07"}/>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={poster8}>
                    <source src={video8} type="video/mp4" />
                </Video>
                <label htmlFor="d8">WALK IN YOUR STRENGTH</label>
                <input id="d8" name="seqd" value="m08" type="radio" aria-label="WALK IN YOUR STRENGTH" onChange={() => livstickApp.clipCustom = "ulta/affirmation08"}/>
              </SequencesListElement>
              </SequencesList>
          </OnDesktop>
          <OnMobile>
            <SequencesList>
              <SequencesListElement>
                <Video controls poster={posterm1}>
                    <source src={videom1} type="video/mp4" />
                </Video>
                <Content>
                  <TitleVideo>SHINE AT FULL WATTAGE</TitleVideo>
                  <Button color={colors.main} hover="main" onClick={() => {
                    livstickApp.clipCustom = "ulta/affirmation01";
                    history.push('record');
                  }}><FormattedMessage id="form_txt_select"/></Button>
                </Content>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={posterm2}>
                    <source src={videom2} type="video/mp4" />
                </Video>
                <Content>
                  <TitleVideo>WELCOME CHANGE</TitleVideo>
                  <Button color={colors.main} hover="main" onClick={() => {
                    livstickApp.clipCustom = "ulta/affirmation02";
                    history.push('record');
                  }}><FormattedMessage id="form_txt_select" /></Button>
                </Content>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={posterm3}>
                    <source src={videom3} type="video/mp4" />
                </Video>
                <Content>
                  <TitleVideo>STRONG AND UNSTOPPABLE</TitleVideo>
                  <Button color={colors.main} hover="main" onClick={() => {
                    livstickApp.clipCustom = "ulta/affirmation03";
                    history.push('record');
                  }}><FormattedMessage id="form_txt_select" /></Button>
                </Content>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={posterm4}>
                    <source src={videom4} type="video/mp4" />
                </Video>
                <Content>
                  <TitleVideo>DEVOTED TO THIS MOMENT</TitleVideo>
                  <Button color={colors.main} hover="main" onClick={() => {
                    livstickApp.clipCustom = "ulta/affirmation04";
                    history.push('record');
                  }}><FormattedMessage id="form_txt_select" /></Button>
                </Content>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={posterm5}>
                    <source src={videom5} type="video/mp4" />
                </Video>
                <Content>
                  <TitleVideo>Layered complex and divine</TitleVideo>
                  <Button color={colors.main} hover="main" onClick={() => {
                    livstickApp.clipCustom = "ulta/affirmation05";
                    history.push('record');
                  }}><FormattedMessage id="form_txt_select" /></Button>
                </Content>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={posterm6}>
                    <source src={videom6} type="video/mp4" />
                </Video>
                <Content>
                  <TitleVideo>FREE AS THE AIR</TitleVideo>
                  <Button color={colors.main} hover="main" onClick={() => {
                    livstickApp.clipCustom = "ulta/affirmation06";
                    history.push('record');
                  }}><FormattedMessage id="form_txt_select" /></Button>
                </Content>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={posterm7}>
                    <source src={videom7} type="video/mp4" />
                </Video>
                <Content>
                  <TitleVideo>SURROUND YOURSELF WITH GOOD</TitleVideo>
                  <Button color={colors.main} hover="main" onClick={() => {
                    livstickApp.clipCustom = "ulta/affirmation07";
                    history.push('record');
                  }}><FormattedMessage id="form_txt_select" /></Button>
                </Content>
              </SequencesListElement>
              <SequencesListElement>
                <Video controls poster={posterm8}>
                    <source src={videom8} type="video/mp4" />
                </Video>
                <Content>
                  <TitleVideo>WALK IN YOUR STRENGTH</TitleVideo>
                  <Button color={colors.main} hover="main" onClick={() => {
                    livstickApp.clipCustom = "ulta/affirmation08";
                    history.push('record');
                  }}><FormattedMessage id="form_txt_select" /></Button>
                </Content>
              </SequencesListElement>
              
            </SequencesList>
          </OnMobile>
          
          
        <OnDesktop>
          <AlignRight>
            <Button color={colors.main} hover="main" onClick={() => history.push('record')}><FormattedMessage id="player_txt_button_next" /></Button>
          </AlignRight>
        </OnDesktop>
        
      </SequencesContent>
      
    </SequencesWrapper>
    }</Observer>
  </Layout>
}

export default SequencesPage;