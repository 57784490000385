import * as React from 'react';
import { LanguagesWrapper, LanguagesSelectElement, LanguagesSelect, LanguagesOption } from './styled';
import { Link } from 'react-router-dom';

import {FormattedMessage} from 'react-intl';
import livstickApp from '../../models/livstick';

const Languages: React.FunctionComponent = () => {
  const language = 'en';
  return <LanguagesWrapper>
    <LanguagesSelectElement>
      <LanguagesSelect onChange={e => {
        livstickApp.changeLang(e.currentTarget.value);
      }}>
        <LanguagesOption selected={language == 'en'} value="en">En</LanguagesOption>
      </LanguagesSelect>
    </LanguagesSelectElement>
  </LanguagesWrapper>;
}

export default Languages;
