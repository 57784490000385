export const events = {
  landing: "LANDING",
  landing_send: "LANDING_SEND",
  landing_sequence: "LANDING",
  player_a: "PLAYER_A",
  player_b: "PLAYER_B",
  player_i: "PLAYER_I",
  player_e: "PLAYER_E",
  player_v: "PLAYER_V",
  form_a: "FORM_A",
  form_v: "FORM_V",
  success: "SUCCESS",
  view: "VIEW"
}