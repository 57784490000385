import styled from "styled-components";
import { colors } from './../../variables';

export const UploadWrapper = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 0px;
  position: relative;
  margin-top: 140px;
  z-index: 30;
  left: 50%;
  margin-left: -200px;
  @media (max-width: 991px){
    position: relative;
    border: 0px;
    margin-top: 50px;
    background-color: transparent;
  }
  @media (max-width: 767px){
    position: relative;
    margin: 0 auto;
    margin-top: 110px;
    width: 300px;
    margin-left: -150px;
  }
`;

export const UploadContent = styled.div`
  padding: 40px 20px;
  color: #000;
  text-align: center;
  @media (max-width: 991px){
    padding: 20px;
    color: #fff;
    padding-bottom: 50px;
  }
`;

export const UploadGif = styled.div`
  text-align: center;
  img{
    width: 120px;
    height: auto;
  }
`;

export const UploadLoader = styled.div`
  font-size: 70px;
  color: #000;
  @media (max-width: 991px){
    color: #fff;
  }
  sup{
    font-size: 20px;
  }
`;

export const UploadLoaderText = styled.div`
  font-size: 14px;
  color: #000;
  @media (max-width: 991px){
    color: #000;
  }
  sup{
    font-size: 20px;
  }
`;

export const UploadTitle = styled.div`
  margin-top: 20px;
  font-size: 22px;
  font-weight: bold;
  font-family: 'Pitch Sans Test';
  text-transform: uppercase;
  color: #000;
  text-align: center;
  @media (max-width: 991px){
    color: #000;
  }
`;

export const UploadMessage = styled.div`
  text-transform: normal;
  font-size: 12px;
  margin-top: 20px;
  color: #555;
  @media (max-width: 991px){
    color: #aaa;
  }
`;