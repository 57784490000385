import axios from 'axios';
import manifest from '../manifest.json';
import * as Crypto from "crypto-js";
import { observable } from 'mobx';
import moment from 'moment';

export const language = observable({
  codeInt: 1,
  code: 'fr'
});

export const progression = observable({
  value: 0,
});

class LivstickApp {
  code:string;
  answer: boolean;
  videoFile: Blob;
  message: string;
  timestamp: string;
  planification: Date;
  planificationTime: Boolean;
  email: string;
  phone: string;
  senderEmail: string;

  clipCustom: string = "ulta/affirmation01";

  previewUrl: string;

  onChangeLang: Function[];

  inProgress: boolean = false;
  uploaded: boolean = false;

  uid: string;

  changeLang(lang) {
    var codes = {
      'fr': 1,
      'en': 2,
      'es': 4,
      'de': 9,
      'it': 5,
      'zh': 12,
      'ja': 13,
    }
    language.codeInt = codes[lang];
    language.code = lang;
    this.onChangeLang.forEach(func => func(lang));
  }
  async postEvent(event: string) {
    var hash = Crypto.SHA256(performance.now());
    var encode = hash.toString(Crypto.enc.Hex);

    this.uid = localStorage.getItem("randToken") || "";
    
    if (!("randToken" in localStorage)) {

      for (let i = 0; this.uid.length < 32; i++) {
        var randChar = Math.floor(Math.random() * encode.length)
       this.uid += encode[randChar]
      }
      localStorage.setItem("randToken", this.uid);
    }
    console.log("event", event, this.uid);
    
    let res = await axios({
      method: 'POST',
      url: `/postEvents/?uid=${this.uid}&event=${event}&code=${this.code}&reseller=${manifest.RESSELER}`,
    });
  }
  async triage() {
    let _res = await axios({
      method: 'GET',
      url: `/triage/?code=${this.code}${this.answer ? '&answer=1': ''}&reseller=${manifest.RESSELER}`,
    });
    return _res.data;
  }

  async pull() {
    let _res = await axios({
      method: 'GET',
      url: `/triage/?code=${this.code}${this.answer ? '&answer=1': ''}&reseller=${manifest.RESSELER}`,
    });
    return _res.data;
  }

  async pullNoLog() {
    let _res = await axios({
      method: 'GET',
      url: `/liv3/pull_text.php?code=${this.code}${this.answer ? '&answer=1': ''}&nolog=1&reseller=${manifest.RESSELER}`,
    });
    return _res.data;
  }

  async scheduleMessage() {
    let _res = await axios({
      method: 'GET',
      url: `/scheduleMessage/?code=${this.code}&reseller=${manifest.RESSELER}&email=${this.senderEmail}&unit=minute&delay=${this.planificationTime}&email_type=RLV_EMAIL_INIT&sms_type=RES_SMS_NOTIF&mobile=${this.phone}`,
    });
    return _res.data;
  }

  async triageCodeVM() {
    if (manifest.CODE_AUTO && !this.answer){
      let _res = await axios({
        method: 'GET',
        url: `/triage/?code=${manifest.CODE_AUTO_CODE}&reseller=${manifest.RESSELER}`,
      });
      this.code = _res.data.code;
    }
  }

  async pushMedia() {
    this.inProgress = true;

    var fd = new FormData();
    var time = new Date().getTime();

    fd.append('timestamp', time.toString());
    fd.append('reseller', manifest.RESSELER.toString());
    fd.append('type', 'VIDEO');
    fd.append('fname', 'video.webm');
    fd.append('file', this.videoFile);
    fd.append('clip', this.clipCustom);

    await this.triageCodeVM();

    fd.append('code', this.code);

    var __r = await axios({
      method: 'GET',
      url: `/storeData/?code=${this.code}&reseller=${manifest.RESSELER.toString()}&receiverEmail=${this.email}&senderEmail=${this.senderEmail}&receiverMobile=${this.phone}`,
    });

    var response = await axios({
      method: 'POST',
      url: "/liv3/upload_file_ulta.php",
      data: fd,
      headers: {'Content-Type': 'multipart/form-data'},
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 90) / progressEvent.total)
        console.log(percentCompleted, progressEvent);
        progression.value = percentCompleted;
      }
    })

    //handle success
    console.log(response);
    var url = response.data.replace("https://fm2x.me", "");
    
    var fd2 = new FormData();
    fd2.append('code', this.code);
    fd2.append('quote', this.message);
    if (this.senderEmail) fd2.append('email', this.senderEmail);
    if (this.phone != null) {
      fd2.append('mobile', '00' + this.phone );
      fd2.append('sms_type', 'RES_SMS_NOTIF');
    }
    fd2.append('type', 'VIDEO');
    fd2.append('reseller', manifest.RESSELER.toString());
    fd2.append('newsletter', '0');
    fd2.append('optin', '1');
    fd2.append('ordtype', manifest.VM ? '2' : '1');
    if (this.planification) {

      const diffTime = Math.abs(this.planification.valueOf() - Date.now());
      const diffMinutes = Math.ceil(diffTime / (60 * 1000)); 
      fd2.append('delay', diffMinutes.toString());
      fd2.append('unit', 'minute');
    } else {
      fd2.append('delay', '1');
      fd2.append('unit', 'minute');
    }
    

    if (this.answer) {
      fd2.append('answer', '1');
    }else{
      fd2.append('answer', '0');
    }

    await axios({
      method: 'POST',
      url: url,
      data: fd2
    });

    this.uploaded = true;
    this.inProgress = false;
  }

  getMedia() {
    axios({
      method: 'GET',
      url: `/liv3/pull_text.php?code=${this.code}`,
    });
  }
}

let livstickApp = new LivstickApp();

export default livstickApp;



