import styled from "styled-components";

export const FooterWrapper = styled.footer`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 0px 0px;
  bottom: 10px;
  left: 10px;
  @media (max-width: 991px){
    position: relative;
    left: inherit;
    bottom: inherit;
    margin-top: 60px;
    text-align: center;
    display: inline-block;
    justify-content: inherit;
    width: 100%;
  }
`;

export const LogoUltaContainer = styled.div`
`;
export const Logo = styled.img`
width: 150px;
margin-right: 30px;
`;

export const ListFooterLink = styled.ul`
  margin: 0px;
  padding: 0px 10px;
  @media (max-width: 991px){
    width: 100%;
    padding: 0px;
    padding-bottom: 30px;
  }
  &.right{
    text-align: right;
    float: right;
    @media (max-width: 991px){
        float: inherit;
        text-align: center;
      }
    li{
      text-align: right;
      float: right;
      &:first-child{
        margin-bottom: 5px;
      }
      @media (max-width: 991px){
        float: inherit;
        text-align: center;
        display: inline-block;
      }
    }
  }
  span{
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
  }
  li {
    list-style: none;
    float: left;
    padding: 0px 4px;
    font-size: 9px;
    color: #000;
    font-family: 'Pitch Sans Test';
    @media (max-width: 991px){
        float: inherit;
        padding: 0px 10px;
        text-align: center;
        color: #000;
        display: inline-block;
      }
    &:last-child{
      border-right: 0px;
    }
    a{
      color: #000;
      font-family: 'Pitch Sans Test';
      text-decoration: inherit;
      margin: 0px;
      font-weight: bold;
      text-transform: uppercase;
      @media (max-width: 991px){
        color: #000;
      }
      &:hover{
        color: #000;
      }
    }
  }
`;