import styled from "styled-components";
import backgroundMobile from './images/background_mobile.jpg';
import backgroundDesktop from './images/background_desktop.jpg';

export const MainWrapper = styled.div`
  background-color: #fff;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const VideoBackground = styled.video`
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    background-size: cover;
    object-fit: cover;
  
`;

export const PictureBackground = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    object-fit: cover;
    background-color: #fff;
    background-size: cover;
    background-position: center right;
    background-image: url(${backgroundDesktop});
    background-repeat: no-repeat;
    @media (max-width: 991px){
      background-position: top center;
      background-image: url(${backgroundMobile});
    }
`;


export const VideoBackgroundMobile = styled.div`
  position: fixed;
  background-size: cover;
  background-position: top right;
  background-color: #fff;
  background-image: url(${backgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
`;

