import styled, { css } from "styled-components";
import icoInfo from './images/ico-info.svg';
import { colors } from './../../variables';

export const Catchline = styled.h1`
  position: relative;
  font-size: 19px;
  font-weight: bold;
  color: #3A5179;
  text-transform: uppercase;
  font-style: normal;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    margin-top: 30px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 20px;
  }
`;

export const LandingWrapper = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  margin-top: 130px;
  z-index: 30;
  left: 50%;
  margin-left: -200px;
  -webkit-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  @media (max-width: 991px){
    position: relative;
    margin-top: 80px;
  }
  @media (max-width: 767px){
    margin-top: 110px;
  }
  @media (max-width: 468px){
    position: relative;
    margin: 0 auto;
    margin-top: 90px;
    width: 95%;
    left: 50%;
    margin-left: -47.5%;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 20%;
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #ccc;
`;

export const LandingContent = styled.div`
  padding: 40px 40px;
  color: #000;
  text-align: center;
`;

export const LandingTitle = styled.div`
  font-weight: 600;
  margin-top: 20px;
  font-size: 24px;
  color: #000;
`;

export const LandingMessage = styled.div`
  font-weight: 400;
  margin-top: 20px;
  font-size: 14px;
`;

export const LandingFormContainer = styled.div`
  
`;

export const LandingForm = styled.div`
  padding: 40px;
  color: #000;
  padding-bottom: 20px;
  @media (max-width: 991px){
    button{
      width: 100%;
    }
  }
  @media (max-width: 767px){
      padding: 20px;
    }
  h3{
    margin-bottom: 20px;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
    button{
      padding: 15px 20px;
      font-size: 14px;
      margin: 0px;
      margin-top: 10px;
      width: 100%;
      text-align: center;
      justify-content: center;
      @media (max-width: 767px){
        flex: inherit;
        font-size: 12px;
        line-height: inherit;
      }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const InputField = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 18px 20px;
  border-radius: 10px;
  color: #000;
  width: 100%;
  display: block;
  position: relative;
  font-size: 14px;
  border: 1px solid #ccc;
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  @media (max-width: 767px){
    padding: 10px 20px;
  }
  &:focus{
    outline: inherit;
  }
`;
export const InputInfoIcon = styled.div<{active?: boolean}>`
  position: absolute;
  right: 20px;
  top: 16px;
  background-image: url(${icoInfo});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  cursor: pointer;
  @media (max-width: 767px){
    top: 8px;
  }
  ${props => props.active && css`
    opacity: 1;
  `}
`;
export const InputInfo = styled.div<{active?: boolean}>`
  position: absolute;
  right: 0px;
  bottom: -90px;
  background-color: #222;
  width: 200px;
  padding: 20px;
  border-radius: 10px;
  font-size: 11px;
  font-style: italic;
  color: #fff;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  &:after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #222;
    right: 17px;
    margin-left: 15px;
  }
  display: none;
  ${props => props.active && css`
    display: block;
  `}
`;